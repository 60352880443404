$border-color: rgba(0, 0, 0, 0.274);
$text-color: #fff;
$project-width: 350px;
$project-height: 210px;
$project-card-border-radius: 20px;
$animation-length: 0.5s;
$from-opacity-transition: 0.7;

.project {
  width: $project-width;
  height: $project-height;
  margin-right: 0px;
  color: $text-color;
  // border-radius: $project-card-border-radius;
  .project-image-container {
    position: relative;
    text-align: center;
    color: #fff;

    .project-card-image {
      width: 100%;
      height: $project-height;
      object-fit: cover;
      border-radius: $project-card-border-radius;
      border: 1px solid $border-color;
    }

    .project-card-overlay {
      display: none;
    }

    &:hover {
      background-color: #000;
      border-radius: $project-card-border-radius;
      
      animation: fadeIn $animation-length;
      -webkit-animation: fadeIn $animation-length;
      -moz-animation: fadeIn $animation-length;
      -o-animation: fadeIn $animation-length;
      -ms-animation: fadeIn $animation-length;

      @keyframes fadeIn {
        0% {opacity:$from-opacity-transition;}
        100% {opacity:1;}
      }
      
      @-moz-keyframes fadeIn {
        0% {opacity:$from-opacity-transition;}
        100% {opacity:1;}
      }
      
      @-webkit-keyframes fadeIn {
        0% {opacity:$from-opacity-transition;}
        100% {opacity:1;}
      }
      
      @-o-keyframes fadeIn {
        0% {opacity:$from-opacity-transition;}
        100% {opacity:1;}
      }
      
      @-ms-keyframes fadeIn {
        0% {opacity:$from-opacity-transition;}
        100% {opacity:1;}
      }

      .project-card-image {
        opacity: 0.3;
      }

      .project-card-overlay {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $text-color;

        .project-card-text {
          padding: 0 10px;
        }

        .project-card-technologies {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
        }
      }
    }
  }
  h6 {
    font-weight: 600 !important;
    text-align: center;
    margin-bottom: 0px;
    padding: 5px 0;
  }
}
