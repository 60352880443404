@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');

$color: #ffffff;

html, body {
  margin: 0;
  // font-family: 'Urbanist', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif!important;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color;
  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
  height: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  width: fit-content;
  height: fit-content;
  margin: 5px;
  border: 1px solid #616161;
  background-color: #fdfdfd;
  &:hover {
    background-color: #acacac;
  }
}

#root {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 40px;
}

p {
  font-size: 20px;
}

ul {
  font-size: 20px;
}
