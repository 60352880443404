$boxes-color: #1a1a1a;
$text-color: #000000;
$button-text-color: #fff;


.home-div {
    width: 100%;

    .canvas-absolute {
        z-index: 1;
        margin-bottom: 40px;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 90px);
    }
    
    .welcome-div {
        position: absolute;
        top: 25%;
        z-index: 2;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;    
        color: $text-color;
        padding: 10px 15px;
        background-color: rgba(255, 255, 255, 0.705);
    
        h4 {
            text-align: center;
            font-size: 40px;
            margin-bottom: 20px;
        }

    }
    .cv-button {
        width: 180px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 8px;
        background-color: $boxes-color;
        color: $button-text-color;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
        transition: transform 0.3s;
        &:hover {
            transform: scale(1.05);
        }


        .download-icon {
            padding-left: 10px;
        }
    }
}



