@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200&display=swap");

$navbar-color: #205081;
$highlight-navbar-item-color: #36618d;
$navbar-height: 50px;
$text-color: #fff;

// a {
//     color: red;
//     text-decoration: none;
// }

.header {

    ul {
        font-size: 18px;
    }

    position: fixed;
    z-index: 3;
    display: flex;
    background-color: $navbar-color;
    justify-content: space-between;
    align-items: center;
    height: $navbar-height;
    width: 100%;

    a:hover {
        color: #e2e2e2;
    }

    .desktop {
        width: 100%;
        height: 100%;
        padding: 0px 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    #logo {
        .logo {
            height: 35px;
        }
    }
    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .nav-options {
        display: flex;
        flex-direction: row;
        list-style: none;
        margin: 0;
    }
    .mobile-option {
        display: none;
    }

    .option {
        display: flex;
        align-items: center;
        padding-right: 20px;
        height: 100%;
        color: $text-color;
        a {
            font-weight: lighter;
        }
    }

    .option :hover {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .mobile-menu {
        display: none;
    }

    @media (max-width: 648px) {
        /*Mobile View */
        .header {
            padding: 0px 10px;
        }
        .nav-options {
            display: flex;
            width: 100%;
            // height: 420px;
            position: absolute;
            top: $navbar-height;
            left: -100%;
            opacity: 0;
            transition: all 0.5s ease;
            flex-direction: column;
            list-style-type: none;
            grid-gap: 0px;
        }
        .nav-options.active {
            background: $navbar-color;
            left: 0;
            opacity: 1;
            transition: all 0.5s ease;
            z-index: 3;
            align-content: center;
            padding-left: 0px;
        }

        .menu-icon {
            margin-right: 15px;
            width: 30px;
        }
        .option {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 10vw;
            padding: 30px 0px;
        }

        .mobile-menu {
            display: block;
            fill: $text-color;
        }
    }

    .active-link {
        font-weight: bold !important;
    }
}
