$padding-portfolio: 15px;
$cards-spacing: 30px;

.portfolio-div {
    padding: 70px $padding-portfolio 0 $padding-portfolio;

    .portfolio-title {
        padding-top: $padding-portfolio;
        text-align: center;
    }

    .portfolio-projects {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        > * {
            margin: $cards-spacing $cards-spacing;
        }

        @media (max-width: 648px) {
            flex-direction: column;
            align-items: center;

            > * {
                margin: $cards-spacing 0px;
            }
        }


    }
}