$footer-color: #205081;
$footer-height: 40px;
$icons-size: 25px;
$icon-margin-top: calc(($footer-height-$icons-size)/2);

.footer-div {
    z-index: 2;
    background-color: $footer-color;
    height: $footer-height;
    width: 100%;
    color: white;
    text-align: center;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    a {
        width: $icons-size;
        height: $icons-size;
        margin-right: 10px;
        &:hover {
            color: unset;
        }
    }

    .footer-icon {
        margin-right: 10px;
        width: $icons-size!important;
        height: $icons-size;
    }
}